<template>
  <div class="developmentDetail">
    <heade></heade>
    <div class="cen_wrap" :style="{
      background: 'url(' + backgroud + ')',
    }">
      <div class="cen">
        <div>
          <div class="prism-player-box">
            <vue-aliplayer-v2 v-if="options.source != ''" ref="VueAliplayerV2" :options="options" />
          </div>
        </div>
      </div>
      <div class="link_box">
        <div class="link" v-for="(item, index) in videoReviews" :key="index">
          <router-link :to="{
            path: pathUrl,
            query: { id: item.id },
          }" :class="queryWhere.id == item.id ? 'addclass' : ''">
            {{ item.name }}
          </router-link>
        </div>
      </div>
    </div>
    <foot></foot>
  </div>
</template>

<script>
import "@css/ico.css";
import "@css/foot.css";
import heade from "@components/heade.vue";
import foot from "@components/foot.vue";
import { getvideoReview, getvideoReviews } from "@api/public";
import VueAliplayerV2 from "vue-aliplayer-v2";
export default {
  components: {
    heade,
    foot,
    VueAliplayerV2,
  },
  props: {},
  watch: {
    $route(to, from) {
      this.$router.go(0);
    },
  },
  data() {
    return {
      videoReview: {},
      videoReviews: [],
      queryWhere: {},
      videoCateWhere: {},
      backgroud: "",
      pathUrl: "",
      options: {
        autoplay: false,
        height: "100%",
        playsinline: true,
        source: "",
        playsinline: true,
        cover: "",
      },
    };
  },
  mounted: function () {
    let that = this;
    getvideoReview(that.queryWhere).then(function (res) {
      that.$set(that, "videoreview", res.data);
      that.$set(that, "backgroud", res.data.background_url);
      that.$set(that.options, "source", res.data.video_url);
    });
    getvideoReviews().then(function (res) {
      that.$set(that, "videoReviews", res.data.list);
    });
  },
  created: function () {
    this.pathUrl = this.$route.meta.path;
    this.queryWhere = this.$route.query;
    document.title = this.$route.meta.title;
  },
  methods: {},
};
</script>

<style>
.developmentDetail .cen_wrap {
  width: 100%;
  height: 1807px;
  background-size: cover !important;
  -webkit-background-size: cover !important;
  -o-background-size: cover !important;
  background-position: center 0 !important;
}

.developmentDetail .cen {
  width: 1184px;
  height: 770px;
  position: relative;
  top: 445px;
  left: 360px;
}

.developmentDetail .link_box {
  width: 800px;
  height: 250px;
  position: relative;
  top: 606px;
  left: 566px;
  display: flex;
  align-items: center;
}

.developmentDetail .link {
  writing-mode: vertical-rl;
  padding-right: 87px;
}

.developmentDetail .link>a {
  color: #006649;
  font-size: 18px;
  font-family: kaiti;
  font-weight: 600;
  line-height: 26px;
}

.prism-player video {
  position: relative !important;
  top: 340px !important;
  height: 770px !important;
}

.prism-player-box>div>div {
  top: 700px;
  left: 0px !important;
}

.developmentDetail .link>.addclass {
  color: #900c0c;
}
</style>